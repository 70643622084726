<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" label-width="96px">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="交易时间" prop="name">
                        <el-date-picker size="small" v-model="dateVal" type="daterange" range-separator="-" style="width: 100%;"
                            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="订单号" prop="orderNo">
                        <el-input v-model="queryParams.orderNo" clearable placeholder="请输入订单号" size="small"
                            @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="上游订单号" prop="outOrderNo">
                        <el-input v-model="queryParams.outOrderNo" clearable placeholder="请输入订单号"
                            size="small" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="商户编号" prop="merchantNo">
                        <el-input v-model="queryParams.merchantNo" clearable placeholder="请输入商户编号"
                            size="small" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="上游商户编号" prop="outMerchantNo">
                        <el-input v-model="queryParams.outMerchantNo" clearable placeholder="请输入商户编号"
                            size="small" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="SN号" prop="name">
                        <el-input v-model="queryParams.sn" clearable placeholder="请输入SN号" size="small"
                            @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="渠道" prop="channelNo">
                        <el-select v-model="queryParams.channelNo" placeholder="渠道" clearable size="small" style="width: 100%;">
                            <el-option label="全部" value="" />
                            <el-option v-for="(item, key) of dicts.CHANNEL" :key="key" :label="item" :value="key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="卡号(末四位)" prop="name">
                        <el-input v-model="queryParams.accountNo" clearable placeholder="请输入卡号(末四位)"
                            size="small" @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="机构编号" prop="name">
                        <el-input v-model="queryParams.orgNo" clearable placeholder="请输入机构编号" size="small"
                            @keyup.enter.native="handleQuery" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="代理商编号" prop="name">
                        <el-input v-model="queryParams.agentNo" clearable placeholder="请输入代理商编号"
                            size="small" @keyup.enter.native="handleQuery"   /> 
                    </el-form-item> 
                </el-col>
                 <el-col :span="6">
                    <el-form-item label="包含下级" prop="name">
                        <el-select v-model="queryParams.openBelowAgent" placeholder="请选择是否包含下级" size="small" style="width: 100%">
                            <el-option v-for="item in booleans" :key="item.label" :label="item.label" :value="item.value"></el-option> 
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="交易状态" prop="name">
                        <el-select v-model="queryParams.transStatus" size="small" placeholder="请选择交易状态" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(value,key) in dicts.TRANS_STATUS" :key="key" :label="value" :value="key"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="交易类型" prop="name">
                        <el-select v-model="queryParams.settleType" placeholder="请选择交易类型" size="small" style="width: 100%">
                            <el-option label="T0" value="0"></el-option>
                            <el-option label="T1" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="结算状态" prop="name">
                        <el-select v-model="queryParams.settleStatus" placeholder="请选择结算状态" size="small" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="结算中" value="UNKNOW"></el-option>
                            <el-option label="结算失败" value="FAIL"></el-option>
                            <el-option label="结算成功" value="SUCCESS"></el-option>
                            <el-option label="已退票" value="REFUNDED"></el-option>
                        </el-select>
        
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="支付类型" prop="name">
                        <el-select v-model="queryParams.payTypeCode" placeholder="请选择支付类型" size="small" style="width: 100%">
                            <el-option v-for="(value, key) in dicts.PAY_TYPE" :key="key" :label="value" :value="key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"
                    v-permission="'TRANS_ORDER_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery"
                    v-permission="'TRANS_ORDER_QUERY'">
                    重置
                </el-button>
                <el-button icon="el-icon-download" size="mini" @click="handleExport"
                    v-permission="'TRANS_ORDER_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { TradeApi } from '@/api';
export default {
    name: "ToolBar",
    props: {
        dicts: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            queryParams: {},
            createDateRange: [],
            updateDateRange: [],
            booleans:[
                {
                    label:'全部',
                    value:true
                },
                {
                    label:'是',
                    value:true
                },
                {
                    label:'否',
                    value:false
                }
            ],
            showCustomForm: false,
            dateVal: '',
            pickerMinDate: null,
            pickerMaxDate: null,
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        const day1 = 90 * 24 * 3600 * 1000;
                        let maxTime = this.pickerMinDate + day1;
                        let minTime = this.pickerMinDate - day1;
                        return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now();
                    } else {
                        return time.getTime() > Date.now()
                    }
                }
            }
        }
    },
    mounted() {
        const date = new Date();
        const year = date.getFullYear().toString().padStart(4, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const today=`${year}-${month}-${day}`;
        console.log(today);
        this.dateVal = [today, today];
    },
    methods: {
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.commitChange();
        },
        handleQuery() {
            this.commitChange();
        },
        handleResetQuery() {
            this.queryParams = {};
            this.createDateRange = [];
            this.updateDateRange = [];
            this.dateVal = []
            this.commitChange();
        },
        handleAdd() {
            this.showCustomForm = true;
        },
        handleExport() {
            this.$confirm("确定导出交易订单",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                this.dealWithQueryParams();
                let result = (await TradeApi.transOrder.export(this.queryParams));
                if (result.success) {
                    this.downloadFile(result.data);
                }
            });
        },
        dealWithQueryParams() {
            console.log(this.dateVal);
            if(!this.dateVal){
                this.dateVal =[]
            }
            let [beginTransTime, endTransTime] = this.dateVal;
            this.queryParams.beginTransTime = beginTransTime && (beginTransTime + ' 00:00:00');
            this.queryParams.endTransTime = endTransTime && (endTransTime + ' 23:59:59');

            let [startUpdateTime, endUpdateTime] = this.updateDateRange;
            this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
            this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
        },
        commitChange() {
            this.dealWithQueryParams();
            let params = { ...this.queryParams };
            this.$emit('on-change', params);
        },
    }
}
</script>

<style scoped></style>
