<template>
<div>
    <el-dialog :visible.sync="open" width="1000px"  @close="commitOnClose">
        <el-table  :data="data" >
            <el-table-column label="分润明细编号" prop="profitDetailNo":show-overflow-tooltip="true"/>
            <el-table-column label="代理商编号" prop="agentNo":show-overflow-tooltip="true"/>
            <el-table-column label="政策编号" prop="policyNo":show-overflow-tooltip="true"/>
            <el-table-column label="T0成本" prop="t0Rate":show-overflow-tooltip="true"/>
            <el-table-column label="T1成本" prop="t1Rate":show-overflow-tooltip="true"/>
            <el-table-column label="提现成本" prop="cashFee":show-overflow-tooltip="true"/>
            <el-table-column label="分润代付开关" prop="profitSwitch":show-overflow-tooltip="true"> 
            </el-table-column>
            <el-table-column label="结算分润代付开关" prop="settleProfitSwitch":show-overflow-tooltip="true"/>
            <el-table-column label="税点" prop="taxPoint":show-overflow-tooltip="true"/>
            <el-table-column label="交易金额" prop="transAmount":show-overflow-tooltip="true"/>
            <el-table-column label="结算方式" prop="settleType":show-overflow-tooltip="true"/>
            <el-table-column label="商户费率" prop="merchantRate":show-overflow-tooltip="true"/>
            <el-table-column label="商户结算手续费" prop="merchantSettleFee":show-overflow-tooltip="true"/>
            <el-table-column label="交易产生的分润金额" prop="transProfitAmount":show-overflow-tooltip="true"/>
            <el-table-column label="交易分润入账金额" prop="transRechargeAmount":show-overflow-tooltip="true"/>
            <el-table-column label="交易分润入账状态" prop="transRechargeStatus":show-overflow-tooltip="true">

            </el-table-column>
            <el-table-column label="交易分润入账时间" prop="transRechargeTime":show-overflow-tooltip="true"/>
            <el-table-column label="交易分润备注" prop="transProfitRemark":show-overflow-tooltip="true"/>
            <el-table-column label="津贴备注" prop="allowanceRemark":show-overflow-tooltip="true"/>
            <el-table-column label="结算产生的分润金额" prop="settleProfitAmount":show-overflow-tooltip="true"/>
            <el-table-column label="结算分润入账金额" prop="settleRechargeAmount":show-overflow-tooltip="true"/>
            <el-table-column label="结算分润入账状态" prop="settleRechargeStatus":show-overflow-tooltip="true"/>
            <el-table-column label="结算分润入账时间" prop="settleRechargeTime":show-overflow-tooltip="true"/>
            <el-table-column label="结算分润备注" prop="settleProfitRemark":show-overflow-tooltip="true"/>
            <el-table-column label="汇总状态" prop="collectStatus":show-overflow-tooltip="true"/>
            <el-table-column label="汇总批次号" prop="batchNo":show-overflow-tooltip="true"/>
            <el-table-column label="汇总时间" prop="batchTime":show-overflow-tooltip="true"/>
             
        </el-table>
    </el-dialog>
</div>
</template>

<script>
export default{
    data(){
        return {
            open:false
        }
    },
    props: {
		data: {
			type: [Array, Object, String]
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
    watch: {
		onFresh: function(value) {
			this.open = value;
		},
		data: function() { 
		}
	},
    mounted(){

    },
    methods:{
        commitOnClose() {
			this.$emit("on-close");
		},
    }
}
</script>