<template>
	<div>
		<el-row style="padding:20px 0 10px">
			<el-col :span="6"
				>交易笔数：{{ allCount.totalOrderCount || 0 }}笔</el-col
			>
			<el-col :span="6"
				>交易金额：{{ allCount.totalTransAmount || 0 }}元</el-col
			>
			<el-col :span="6"
				>结算金额：{{ allCount.totalSettleAmount || 0 }}元</el-col
			>
		</el-row>
		<el-table stripe v-loading="loading" :data="dataList">
			<el-table-column
				label="订单号"
				prop="orderNo"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="上游订单号"
				prop="outOrderNo"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="支付类型"
				prop="payTypeCode"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.payTypeCode | payType }}
				</template>
			</el-table-column>
			<el-table-column
				label="商户编号"
				prop="merchantNo"
				min-width="160"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="上游商户编号"
				prop="outMerchantNo"
				min-width="160"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="交易时间"
				prop="transTime"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="交易金额(元)"
				prop="transAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="交易手续费(元)"
				prop="merchantFee"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="结算手续费(元)"
				prop="settleFee"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="费率"
				prop="merchantRate"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="结算金额"
				prop="settleAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="渠道"
				prop="channelNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.channelNo | channelFM }}
				</template>
			</el-table-column>
			<el-table-column label="一级代理商" prop="oneAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.oneAgentNo}} - {{scope.row.oneAgentName}}
                </template>
            </el-table-column>
            <el-table-column label="代理商" prop="agentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}} - {{scope.row.agentName}}
                </template>
            </el-table-column>
			<el-table-column
				label="SN号"
				prop="sn"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="卡类型"
				prop="cardType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.cardType | formateCardType }}
				</template>
			</el-table-column>
			<el-table-column
				label="交易状态"
				prop="transStatus"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
                    <el-button :type="scope.row.transStatus=='SUCCESS'?'success':scope.row.transStatus=='FAILED'?'danger':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.transStatus">{{scope.row.transStatus | transStatusFM}}</el-button>
                </template>
			</el-table-column>
			<el-table-column
				label="交易类型"
				prop="settleType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					<template v-if="scope.row.settleType">T</template
					>{{ scope.row.settleType }}
				</template>
			</el-table-column>
			<el-table-column
				label="结算状态"
				prop="settleStatus"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
                    <el-button :type="scope.row.settleStatus=='SUCCESS'?'success':scope.row.settleStatus=='FAIL'?'danger':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.settleStatus">{{scope.row.settleStatus | settleStatusFM}}</el-button>
                </template>
			</el-table-column>
			<!-- 分润已计算 -->
			<el-table-column
				label="分润已计算"
				prop="hasTransProfit"
				min-width="180"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
				<a @click="showLog(scope.row)" >	{{ scope.row.hasTransProfit == "0" ? "否" : "是" }}</a>
				</template>
			</el-table-column>
			<el-table-column
				label="分润入账时间"
				prop="profitRechargeTime"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
		</el-table>

		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
		
        <Log :data="logInfo" :on-fresh="showLogDialog"  @on-close="onClose"></Log>
	</div>
</template>

<script>
import { TradeApi } from "@/api";
import Log from "./Log";
export default {
	name: "ToolBar",
	components:{
		Log
	},
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [],
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			allCount: "",
			logInfo:[],
			showLogDialog:false
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		async getList() {
			this.loading = true;
			let result = await TradeApi.queryPage(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data.orderPageList || [];
			this.allCount = result.data.orderCountInfo || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		onClose(){
			this.showLogDialog=false;
		},
		async handleUpdate(row) {
			this.showCustomForm = true;
			this.selectRow = row;
			this.isAdd = false;
		},
		async showLog(row){
			this.showLogDialog=true;
			let result=await TradeApi.queryByTransOrderNo(row.orderNo);
			const transRechargeStatuses={
				0:'未入账',
				1:'已入账',
				2:'入账失败',
				3:'无需入账'
			};
			console.log(result);
			if(result.data&&result.data.length>0){
				result.data.forEach((item)=>{
					item.profitSwitch=item.profitSwitch=='0'?'关闭':'打开';
					item.settleProfitSwitch=item.settleProfitSwitch=='0'?'关闭':'打开';
					item.transRechargeStatus=transRechargeStatuses[item.transRechargeStatus];
					item.settleRechargeStatus=transRechargeStatuses[item.settleRechargeStatus];
				});
			}
			this.logInfo=result.data;
		}
	}
};
</script>

<style scoped></style>
